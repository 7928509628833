<template>
  <div class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <main>
      <div class="main_content align-items-center">
        <div class="row justify-content-between mb_40">
          <div class="col-md-12">
            <div class="heading-admincenter">               
                <h2>Welcome To Admin Center</h2> 
                <p v-show="admin_per"> You don't have any admin role permission</p>             
            </div>            
          </div>
        </div>        
      </div>
    </main>    
  </div>
</template>
<script>
  
  export default {
    name: "admin_center",
    data() {
      return {
        admin_per: false,
      };
    },
    beforeMount() {
      document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
      // this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      if(localStorage.getItem("userToken")){
        // Store for check permission
        let res = this.$store.dispatch("auth/getAuthUser");
          res.then(value => {        
            let allPermission = value.user.roles.all_permission;  
            let permission = ['role-view','users-view','clients-view','email-templates-view','send-notification-view','audit-log-viewer-view','blog-admin-center-view'] 
            var per = false;
            permission.forEach(function(value) {
              if(allPermission.includes(value)){ 
                per = true;                
              }
            });
            if(per == false){
                this.admin_per = true;
            }                         
          }).catch(err => {
            console.log(err);
          });
      }else{
        window.localStorage.removeItem("user_profile");
        window.localStorage.removeItem("id_token_client");
        window.localStorage.removeItem("userToken");
        window.localStorage.removeItem("companyInformation");
        window.localStorage.removeItem("loginType");
        window.localStorage.removeItem("employeeEmail");
        //router.push({name:"login"});
        
      }
    }, 
  };
</script>